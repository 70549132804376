import clsx from "clsx";
import React from "react";
import "./detailPageFooterActions.scss";

interface IDetailPageFooterActionsProps extends React.ComponentPropsWithoutRef<"div"> {
    /**
     * @default "end"
     */
    placement?: DetailPageFooterPlacement;
}

export const DetailPageFooterActions = (props: IDetailPageFooterActionsProps) => {
    const { placement = "end", ...restProps } = props;

    return (
        <div
            {...restProps}
            className={clsx(
                "detail-page-footer-actions",
                {
                    "detail-page-footer-actions--start": placement === "start",
                    "detail-page-footer-actions--end": placement === "end",
                },
                restProps.className
            )}
        />
    );
};

type DetailPageFooterPlacement = "start" | "end";
