import React from "react";
import "./detailPageFooterRoot.scss";
import { IonFooter } from "@ionic/react";
import clsx from "clsx";

interface IDetailPageFooterProps extends React.ComponentPropsWithoutRef<"div"> { }

export const DetailPageFooterRoot = (props: IDetailPageFooterProps) => {
    return (
        <IonFooter>
            <div className="detail-page-footer-root">
                <div {...props} className={clsx("detail-page-footer-root__content", props.className)} />
            </div>
        </IonFooter>
    );
};
